/*
 * Load Main Bootstrap LESS files
 */


@import '../vendors/bower_components/bootstrap/less/bootstrap';

/*
 * LESS Plugins
 */
@import 'inc/less-plugins/for';


/*
 * Variable and Mixin
 */

@import 'inc/variables';
@import 'inc/mixin';

/*
 * Load Font
 */
@import 'inc/font';

/*
 * Vendors
 */
@import '../vendors/weather-icons/weather-icons';
@import '../vendors/fileinput/fileinput';
@import '../vendors/bower_components/material-shadows/material-shadows';
@import '../vendors/bower_components/Waves/src/less/waves';

/*
 * Load Website related LESS files
 */
@import 'inc/generics';
@import 'inc/bootstrap-overrides';
@import 'inc/base';
@import 'inc/header';
@import 'inc/sidebar';
@import 'inc/dropdown';
@import 'inc/listview';
@import 'inc/progress-bar';
@import 'inc/chat';
@import 'inc/tabs';
@import 'inc/card';
@import 'inc/chart';
@import 'inc/widgets';
@import 'inc/table';
@import 'inc/todo';
@import 'inc/button';
@import 'inc/form';
@import 'inc/pagination';
@import 'inc/popover';
@import 'inc/wizard';
@import 'inc/alert';
@import 'inc/media';
@import 'inc/modal';
@import 'inc/panel';
@import 'inc/tooltip';
@import 'inc/breadcrumb';
@import 'inc/messages';
@import 'inc/404';
@import 'inc/login';
@import 'inc/profile';
@import 'inc/photos';
@import 'inc/contacts';
@import 'inc/shadow';
@import 'inc/misc';
@import 'inc/ie-warning';
@import 'inc/load';
@import 'inc/footer';
@import 'inc/pricing-table';
@import 'inc/invoice';
@import 'inc/wall';

@import 'inc/print';

/*
 * Vendor Overrides
 */
@import 'inc/vendor-overrides/mediaelement';
@import 'inc/vendor-overrides/fullcalendar';
@import 'inc/vendor-overrides/bootstrap-chosen';
@import 'inc/vendor-overrides/noUiSlider';
@import 'inc/vendor-overrides/farbtastic';
@import 'inc/vendor-overrides/summernote';
@import 'inc/vendor-overrides/bootstrap-datetimepicker';
@import 'inc/vendor-overrides/fileinput';
@import 'inc/vendor-overrides/light-gallery';
@import 'inc/vendor-overrides/waves';
@import 'inc/vendor-overrides/ui-bootstrap';
@import 'inc/vendor-overrides/ng-table';
@import 'inc/vendor-overrides/sweetalert'; 
@import '../vendors/bower_components/font-awesome/less/font-awesome.less';
@import 'custom.less';
@import '../vendors/bower_components/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';



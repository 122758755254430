/*
 * Font Face
 */
.font-face(@family, @name, @weight: 300, @style){
    @font-face{
        font-family: @family;
        src:url('../fonts/@{family}/@{name}.eot');
        src:url('../fonts/@{family}/@{name}.eot?#iefix') format('embedded-opentype'),
            url('../fonts/@{family}/@{name}.woff') format('woff'),
            url('../fonts/@{family}/@{name}.ttf') format('truetype'),
            url('../fonts/@{family}/@{name}.svg#icon') format('svg');
        font-weight: @weight;
        font-style: @style;
    }
}

/*
 * Background Repeat + Position
 */

.bg-option(@repeat: no-repeat, @position: center) {
    background-repeat: @repeat;
    background-position: @position;
}

/*
 * CSS Animations based on animate.css
 */

.animated(@name, @duration) {
    -webkit-animation-name: @name;
    animation-name: @name;
    -webkit-animation-duration: @duration;
    animation-duration: @duration;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/*
 * CSS Transform - Scale and Rotate
 */
.scale-rotate(@scale, @rotate) {
    -webkit-transform: scale(@scale) rotate(@rotate);
    -ms-transform: scale(@scale) rotate(@rotate);
    -o-transform: scale(@scale) rotate(@rotate);
    transform: scale(@scale) rotate(@rotate);
}

/*
 * User Select
 */
.user-select (@val) {
    -webkit-touch-callout:  @val;
    -webkit-user-select:    @val;
    -khtml-user-select:     @val;
    -moz-user-select:       @val;
    -ms-user-select:        @val;
    user-select:            @val;
}

/*
 * Background Image Cover
 */
.bg-cover(@image) {
    background-image: url(@image);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.bg-cover-inline() {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

/*
 * Tab Focus
 */
.tab-focus() {
    outline: none !important;
}

/*
 * Pop-in Hover effects
 */
.hover-pop(@background: ~"rgba(0,0,0,0.5)", @radius: 0, @duration: 250ms, @zindex: 0) {
    position: relative;

    &:before {
        left: 0;
        top: 0;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        .scale3d(0,0,0);
        .transition(all);
        .transition-duration(@duration);
        .backface-visibility(hidden);
        background-color: @background;
        z-index: @zindex;
        border-radius: @radius;
        .opacity(0);
    }

    &:hover {
        &:before {
            .scale3d(1,1,1);
            .opacity(1);
        }
    }
}

/*
 *  Override Bootstrap Button Mixin
 */
.button-variant(@color; @background; @border) {
    color: @color;
    background-color: @background;
    border-color: @border;

    &:focus,
    &.focus {
        color: @color;
        background-color: @background;
        border-color: transparent;
    }

    &:active,
    &.active,
    .open > .dropdown-toggle {
        background-image: none;
        background-color: darken(@background, 15%);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled],
    &:hover,
    &:focus,
    &.focus,
    &:active {
        background-color: @background;
        border-color: @border;
    }


    .badge {
        color: @background;
        background-color: @color;
    }
}

/*
 * Scale 3d
 */
.scale3d(...) {
  @process: ~`(function(e){return e=e||"1, 1, 1"})((function(){var e="@{arguments}";return e=e.replace(/^\[|\]$/g,"")})())`;
  -webkit-transform: scale3d(@process);
  -moz-transform: scale3d(@process);
  -ms-transform: scale3d(@process);
  -o-transform: scale3d(@process);
  transform: scale3d(@process);
}

/*
 * Font Icon Family
 */
@font-icon-md:              'Material-Design-Iconic-Font';


/*
 * Grid System
 */
@container-tablet:             ~"calc(100% - @{grid-gutter-width})";
@container-desktop:            ~"calc(100% - @{grid-gutter-width})";


/* Typography + Scaffolding + Links */
@body-bg:                   #edecec;
@text-color:                #5E5E5E;
@font-family-sans-serif:    roboto;
@font-size-base:            13px;
@link-hover-decoration:     none;
@headings-color:			#000000;

/* Border Radius */ 
@border-radius-base:        2px;
@border-radius-large:       4px;
@border-radius-small:       2px;

/* Colors */
@brand-primary:             #2196F3;
@brand-success:             #4CAF50;
@brand-info:                #00BCD4;
@brand-warning:             #FF9800;
@brand-danger:              #F44336;
@app-gray:                  #F7F7F7;   

/* Tabs */
@nav-tabs-border-color:                     #fff;
@nav-tabs-active-link-hover-border-color:   #fff;
@nav-tabs-active-link-hover-bg:             transparent;

/* Form */
@input-border:              #e0e0e0;
@input-border-radius:       0;
@input-border-radius-large: 0px;
@input-height-large:        40px;
@input-height-base:         35px;
@input-height-small:        30px;
@input-border-focus:        #b4b4b4;

/* Table */
@table-border-color:            #f0f0f0;
@table-cell-padding:            13px;
@table-condensed-cell-padding:  10px;
@table-bg-accent:               #f4f4f4;
@table-bg-active:               #FFFCBE;

/*
 * Input Group
 */
@input-group-addon-bg: transparent;
@input-group-addon-border-color: transparent;

/*
 * Pagination
 */
@pagination-bg:                 #E2E2E2;
@pagination-border:             #fff;
@pagination-color:              #7E7E7E;
@pagination-active-bg:          @m-cyan;
@pagination-active-border:      @pagination-border;
@pagination-disabled-bg:        #E2E2E2;
@pagination-disabled-border:    @pagination-border;
@pagination-hover-color:        #333;
@pagination-hover-bg:           #d7d7d7;
@pagination-hover-border:       @pagination-border;
@pager-border-radius:           5px;

/*
 * Popover
 */
@zindex-popover:                9;
@popover-title-bg:              #fff;
@popover-border-color:          #fff;
@popover-fallback-border-color: #fff;

/*
 * Dropdown
 */
@dropdown-fallback-border:      transparent;
@dropdown-border:               transparent;
@dropdown-divider-bg:           '';
@dropdown-link-hover-bg:        rgba(0,0,0,0.075);
@dropdown-link-color:           #333;
@dropdown-link-hover-color:     #333;
@dropdown-link-disabled-color:  #e4e4e4;
@dropdown-divider-bg:           rgba(0,0,0,0.08);
@dropdown-link-active-color:    #333;
@dropdown-link-active-bg:       rgba(0, 0, 0, 0.075);
@zindex-dropdown:               9;

/*
 * Thumbnail
 */
@thumbnail-bg:                  #fff;

/*
 * Form Stats and Alerts
 */
@state-success-text:            @m-green;
@state-info-text:               @m-blue;
@state-danger-text:             @m-red;
@state-warning-text:            @m-orange;
@alert-success-border:          @alert-success-bg;
@alert-info-border:		 		@alert-info-bg;
@alert-warning-bg:              #FFEDD6;
@alert-warning-border:		 	@alert-warning-bg;
@alert-danger-border:		 	@alert-danger-bg;

/*
 * Buttons
 */
@border-radius-base:            2px;
@border-radius-large:           2px; 
@border-radius-small:           2px;
@btn-font-weight:				400;

/*
 * Thumbnail
 */
@thumbnail-border:				#EDEDED;
@thumbnail-padding:				3px;

/*
 * Carousel
 */
@carousel-caption-color:        #fff;

/*
 * Modal
 */
@modal-content-fallback-border-color:	transparent;
@modal-content-border-color:			transparent;
@modal-backdrop-bg:						#000;
@modal-header-border-color:				transparent;
@modal-title-line-height:				transparent;
@modal-footer-border-color:				transparent;
@zindex-modal-background: 				0;
 
/*
 * Tooltips
 */
@tooltip-bg:                #737373;
@tooltip-opacity:           1;

/*
 * Popover
 */
@popover-title-bg:              #fff;
@popover-border-color:          #fff;
@popover-fallback-border-color: #fff;

/*
 * Breadcrumbs
 */
@breadcrumb-bg:            transparent;
@breadcrumb-padding-horizontal: 20px;
@breadcrumb-active-color:       #7c7c7c;

/*
 * Jumbotron
 */
@jumbotron-bg:              #F7F7F7;

/*
 * List Groups
 */
@list-group-border:                 #E9E9E9; 
@list-group-active-color:           #000;
@list-group-active-bg:              #f5f5f5;  
@list-group-active-border:          #e9e9e9;
@list-group-disabled-color:         #B5B4B4;
@list-group-disabled-bg:            #fff;
@list-group-disabled-text-color:    #B5B4B4;

/*
 * Badges
 */
@badge-color:               #fff;
@badge-bg:                  @brand-primary;
@badge-border-radius:       2px;
@badge-font-weight:         400;
@badge-active-color:        #fff;
@badge-active-bg:           @brand-primary;
    
/*
 * Material Colors
 */
@m-white:                  #ffffff;
@m-black:                  #000000;
@m-blue:                   #2196F3;
@m-red:                    #F44336;
@m-purple:                 #9C27B0;
@m-deeppurple:             #673AB7;
@m-lightblue:              #03A9F4;  
@m-cyan:                   #00BCD4;
@m-teal:                   #009688;
@m-green:                  #4CAF50;
@m-lightgreen:             #8BC34A;
@m-lime:                   #CDDC39;
@m-yellow:                 #FFEB3B;
@m-amber:                  #FFC107;
@m-orange:                 #FF9800;
@m-deeporange:             #FF5722;
@m-gray:                   #9E9E9E;
@m-bluegray:               #607D8B;
@m-indigo:                 #3F51B5;
@m-pink:                   #E91E63;
@m-brown:                  #795548;

/* 
 * Blocks
 */
@sidebar-left-width: 268px;
@sidebar-right-width: 280px;
@footer-height: 110px;
@header-height: 65px;
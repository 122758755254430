.popover {
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
}

.popover-title {
    border-bottom: 0;
    padding: 15px; 
    font-size: 12px;
    text-transform: uppercase;
    
    & + .popover-content { 
        padding-top: 0;
    }
}   

.popover-content {
    padding: 15px; 
    
    p {
        margin-bottom: 0; 
    }
} 
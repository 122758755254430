/*
 * Block Header
 * Used for Heading outside the Cards.
 */
.block-header {
    @media screen and (min-width: @screen-sm-min) {
        padding: 0 22px;
    }
    
    @media screen and (max-width: @screen-sm-max) {
        padding: 0 18px;
    }
    
    margin-bottom: 25px;
    position: relative;
    
    & > h2 {
        font-size: 15px;
        color: #777;
        margin: 0;
        font-weight: 400;
        text-transform: uppercase;
        
        & > small {
            display: block;
            text-transform: none;
            margin-top: 8px;
            margin-bottom: 20px;
            color: #9E9E9E;
            line-height: 140%; 
        }
    }
    
    .actions {
        position: absolute; 
        right: 10px;
        top: -5px;
        z-index: 5;
    }
}

.block-header-alt {
    h1, h2, h3, h4, h5 {
        color: #575757;
        font-weight: 100;
    }
}

/*
 * Header Actions
 */
.actions {
    list-style: none;
    padding: 0;
    z-index: 2; 
    margin: 0;
    
    & > li {
        display: inline-block;
        vertical-align: baseline;
    }
    
    & > li > a,
    & > a {
        width: 30px;
        height: 30px;
        line-height: 35px;
        display: inline-block; 
        text-align: center;

        & > i {
            .transition(color);
            .transition-duration(1300ms);
            color: #adadad;
            font-size: 20px;
        }
        
        &:hover {
            & > i {
                color: #000;
            }
        }

        .hover-pop(rgba(0,0,0,0.1), 50%, 250ms, 0);
    }
    
    & > li.open, 
    &.open {
        & > a {
            & > i {
                color: #000;
            }
            
            &:before {
                .scale(1);
                .opacity(1);
            }
        }
    }
    
    &.actions-alt {
        & > li {
            & > a > i {
                color: #fff;
                
                &:hover {
                    color: #fff;
                }
            }
            
            &.open {
                & > a {
                    & > i {
                        color: #fff;
                    }
                }
            }
        }
    }
    
    &.open {
        z-index: 3;
    }
}

/*
 * Collapse Menu Icons
 */
.line-wrap {
    width: 18px;
    height: 12px;
    .transition(all);
    .transition-duration(300ms);
    margin: 10px 20px;

    .line{
        width: 18px;
        height: 2px;
        .transition(all);
        .transition-duration(300ms);
        
        &.center {
            margin: 3px 0;
        }
    }
}

&.open {
    .line-wrap {
        .rotate(180deg);
        
        .line {
            &.top {
                width: 12px;
                transform: translateX(8px) translateY(1px) rotate(45deg);
                -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
            }
            
            &.bottom {
                width: 12px;
                transform: translateX(8px) translateY(-1px) rotate(-45deg);
                -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
            }
        }
    }
}

/*
 * Load More
 */
.load-more {
    text-align: center;
    margin-top: 30px; 
    
    a {
        padding: 5px 10px 4px;
        display: inline-block;
        background-color: @m-red;
        color: #FFF;
        border-radius: 2px;
        white-space: nowrap;
        
        i {
            font-size: 20px;
            vertical-align: middle;
            position: relative;
            margin-right: 5px;
        }
        
        &:hover {
            background-color: darken(@m-red, 10%);
        }
    }
}

/*
 * Link titles
 */
.a-title {
    color: #333;
    font-weight: 500;
    
    &:hover {
        color: #333;
        text-decoration: underline;
    }
}

/*
 * Container
 */
.container-alt {
    max-width: 1170px;
}
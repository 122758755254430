.col-xsl-1, 
.col-xsl-2, 
.col-xsl-3, 
.col-xsl-4, 
.col-xsl-5, 
.col-xsl-6, 
.col-xsl-7, 
.col-xsl-8, 
.col-xsl-9, 
.col-xsl-10, 
.col-xsl-11, 
.col-xsl-12

 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}
@media (min-width: 468px) {
  .make-grid(xsl);
}

.page        {
    bottom:0; 
    
    position:absolute; 
    z-index:1 !important;
    top:0;  
    width:100%; 
	&.ng-enter           { animation: slideInLeft 0.2s both ease-in; z-index: 8888; }
    &.ng-leave           { animation: slideOutRight 0.2s both ease-in; z-index: 9999; }
}
#sidebar {
	z-index:2;
}
/* slide in from the right */
@keyframes slideInLeft {
    from    { transform:translateX(-100%); }
    to      { transform: translateX(0); }
}
/* slide in from the bottom */
@keyframes slideOutRight {
    to      { transform: translateX(100%); }
}

body.sw-toggled #content {
	padding-bottom:0;
}
.cropArea {
    background: #E4E4E4;
    overflow: hidden;
    width:500px;
    height:350px;
}
.drop-box {
	width:100%;
	padding-bottom:100%;
		text-align:center;
	position:relative;
	z-index:1;
	img {
		max-width:100%;
		width:100%;
	}
	.img-preview{
		top:0;
		bottom:0;
		left:0;
		right:0;
		position:absolute;
		background-size:cover;
		background-position:50% 50%;
		z-index:1;
	}
	.img-upload-btn-holder {
		position:absolute;
		top:0;
		left:0;
		right:0;
		background:rgba(0,0,0,.5);
		padding:5px 0;
		z-index:2;
		a {
			color:#FFF;
			cursor:pointer;
		}
	}
}

.user-avatar {
	width:100%;
	padding-bottom:100%;
	background-size:cover;
	background-repeat:no-repeat;
	background-position:50% 50%;
}
.lockscreen {
	background:#FFF;
	padding:60px 30px 30px 30px;
}
.lockout-icon {
	font-size:80px;
	line-height:100px;
    width: 100px;
    height: 100px;
    color:#FFF;
   text-align:center;
    position: absolute;
    top: -60px;
    left: 50%;
    margin-left: -50px; 
	box-shadow:0 0 4px #ff9800;
    
}
    
.user-avatar-mini {
	display: inline-block;
    width: 32px; 
    height: 32px;
     
    background-size: cover; 
    background-position: center center;
    vertical-align: middle;
    line-height: 32px;
    box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0,0,0,.2);
    margin-left: 5px;
	vertical-align:middle;
}
.k-grid {
	.user-name {
		display:inline-block;
		margin-left:5px; 
		width:70%;
		vertical-align:middle;
	}
}

.toggle-switch[data-ts-color="lime"] input:checked + .ts-helper {
	background:rgba(205,220,57,0.5);
}
.toggle-switch[data-ts-color="lime"] input:checked + .ts-helper:before {
	background:#cddc39;
}

.special {
.sweet-overlay {
  background: rgba(10, 10, 10, 0.6); }

.sweet-alert {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 24px;
  padding-top: 64px;
  padding-bottom: 13px;
  text-align: right;
  border-radius: 0;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.24), 0 14px 28px rgba(0, 0, 0, 0.48); }
  .sweet-alert h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    font-weight: 400;
    color: #212121;
    margin: 20px 0;
    font-size: 1.2em;
    line-height: 1.25;
    text-align: left;
    padding: 0 24px; }
  .sweet-alert p {
    display: block;
    text-align: center;
    color: #212121;
    font-weight: 400;
    font-size: 14px;
    margin: 20px 0; }
  .sweet-alert button {
    border-radius: 2px;
    box-shadow: none !important;
    background: none !important;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    position: relative;
    margin-top: 0; }
    .sweet-alert button:hover, .sweet-alert button:focus {
      background-color: #f6f6f6 !important; }
    .sweet-alert button.confirm {
      color: #3c80f6; }
    .sweet-alert button.cancel {
      color: #757575; }
      .sweet-alert button.cancel:focus {
        box-shadow: none !important; }
  .sweet-alert .sa-icon:not(.sa-custom) {
    transform: scale(0.8);
    margin-bottom: -10px;
    margin-top: -10px; }
  .sweet-alert input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c9c9c9;
    color: #212121;
    margin-bottom: 8px;
    padding: 1px;
    padding-bottom: 8px;
    height: auto;
    box-shadow: none;
    font-size: 13px;
    margin: 10px 0; }
    .sweet-alert input:focus {
      border: none;
      border-bottom: 1px solid #3c80f6;
      box-shadow: inset 0 -1px 0 #3c80f6; }
  .sweet-alert fieldset {
    padding: 0; }
    .sweet-alert fieldset .sa-input-error {
      display: none; }
  .sweet-alert .sa-error-container {
    display: none;
    background: none;
    height: auto;
    padding: 0 24px;
    margin: 0 -20px;
    text-align: left; }
    .sweet-alert .sa-error-container.show {
      padding: 0 24px;
      display: block; }
      .sweet-alert .sa-error-container.show ~ fieldset input {
        background: red;
        border-bottom: 1px solid #d9453c;
        box-shadow: inset 0 -1px 0 #d9453c; }
    .sweet-alert .sa-error-container .icon {
      display: none; }
    .sweet-alert .sa-error-container p {
      color: #d9453c;
      margin-top: 0; }
.sweet-alert.login .sa-icon {
	margin:5px auto;
	padding:0;
	background-size:cover;
}
}
body.login-content {
	text-align:left;
	&:before {
		background:transparent;
	}
	.page {
		display: flex;
		  align-items: center;
		  justify-content: center;
	}
	.vcenter {
		flex:1;
	}
} 


.k-grid td {
	padding:4px 14px!important;
}

.lockScreen {
	text-align:center;
	.modal-content {
		background:transparent;
		box-shadow:0 0 0 0;
	}
	.modal-dialog {
	  display: inline-block;
	  text-align: left;
	  vertical-align: middle;
	}
	.form-group {
		margin-bottom:0;
	}
}
@media screen and (min-width: 768px) { 
  .lockScreen:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100vh;
  }
}
@-webkit-keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg), scale(0.5);
    -webkit-transform: rotateX(100deg), scale(0.5);
    opacity: 0; }

  100% {
    transform: rotateX(0deg), scale(0.5);
    -webkit-transform: rotateX(0deg), scale(0.5);
    opacity: 1; } }

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg), scale(0.5);
    -webkit-transform: rotateX(100deg), scale(0.5);
    opacity: 0; }

  100% {
    transform: rotateX(0deg), scale(0.5);
    -webkit-transform: rotateX(0deg), scale(0.5);
    opacity: 1; } }
	
.table {
	tr.unread {
		td {
			font-weight:bold;
		}
	}
}
.message {
	color:#FFF;
	> div {
	background:@m-lightgreen;
	}
	&.own-message > div {
		background:@m-gray;
	}
	.sender {
		font-weight:bold;
	}
}
 

.message-alt {
	
	 
	&.own-message .card-header {
		 
	}
	.sender {
		font-weight:bold;
	}
}
.message-content{
	white-space:pre-line;
}
.message-date {
	small {
		font-style:italic;
		cursor:help;
	}
}
.date-tooltip {
	.tooltip-inner {
		border-radius: 2px;
		padding: 3px 10px 5px;
		font-size:10px;
	}
	
}

.k-list-container, .k-dropdown {
	
	.k-item , .k-list-optionlabel{
		line-height:32px; 
		.user-name {
			display:inline-block;
			margin-left:5px;
			line-height:32px; 
		}
		.user-avatar-mini {
			line-height:32px; 
		}
	}
	.selected-value {
		.user-avatar-mini {
			display:none;
		}
	}
	.k-item2 > span:first-child {
		    -webkit-box-shadow: inset 0 0 30px rgba(0,0,0,.3);
			box-shadow: inset 0 0 30px rgba(0,0,0,.3);
			margin: 10px;
			margin-top: 10px;
			margin-right: 10px;
			margin-bottom: 10px;
			margin-left: 10px;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-size: 100%;
			background-repeat: no-repeat;
	}
	
	
	 
}
